.px-preorder {
  .px-min-width-buyer-table {
    min-width: 2000px;
  }

  .px-min-width-item-table {
    min-width: 1800px;
  }

  //horizontall scroll
  .ag-theme-alpine .ag-body-horizontal-scroll {
    position: fixed;
    bottom: 0px;
    padding-right: 100px;
  }

  @media screen and (max-width: 900px) {
    .ag-theme-alpine .ag-body-horizontal-scroll {
      position: fixed;
      bottom: 54px;
      padding-right: 100px;
    }
  }

  .ag-details-row .ag-body-horizontal-scroll {
    position: fixed;
    bottom: 0px;
    padding-right: 10px;
  }

  .ag-theme-alpine {
    .ag-row {
      margin: 0;
      border: 0;
    }

    .ag-cell {
      // line-height: 24px;
      // padding: 8px 0;
      margin: 0;
    }

    [col-id='items'],
    .ag-cell[col-id='warehouse'] {
      padding-left: 4px;
    }

    // div.ag-checkbox-input {
    //   // background-color: RGB(var(--px_color_primary));
    //   border: RGB(var(--px_color_primary));
    // }

    // div.ag-checkbox-input-wrapper:hover {
    //   &::after {
    //     color: RGB(var(--px_color_text_on_primary));
    //     background-color: RGB(var(--px_color_primary));
    //   }

    //   &.ag-checkbox-input {
    //     // background-color: RGB(var(--px_color_primary));
    //     background-color: RGB(var(--px_color_primary));
    //   }
    // }

    span.ag-cell-wrapper {
      padding: 0;
      display: flex;
      flex-direction: row;
      // flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  // .ag-cell-value,
  // .ag-group-value {
  //   // line-height: 40px;
  // }

  .ag-group-contracted,
  .ag-group-expanded {
    z-index: 2;
  }

  .cell-vertical-align-text-left {
    display: flex !important;
    align-items: center;
  }

  .cell-vertical-align-text-left-open {
    margin: 7px;
    // display: flex !important;
    border: 1px !important;
    padding-right: 10px !important;
    align-items: center;
  }

  .ag-row-level-1 {
    .ag-row-selected {
      // #FFE00014 => It's not related to primary color => do we want to continue?
      background: #ffe00014 !important;
      // background: RGBA(var(--px_color_primary), var(--px_alpha_muted)) !important;
    }
  }

  .ag-group-value {
    word-break: break-word;
  }

  .ag-react-container {
    word-break: break-word;
  }

  .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper,
  .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container,
  .ag-theme-alpine .ag-layout-print .ag-center-cols-clipper,
  .ag-theme-alpine .ag-layout-print .ag-center-cols-container {
    min-height: 50px;
  }

  .cell-vertical-align-text-left-detail-grid {
    margin: 3.5px;
    border: 1px !important;
    padding-right: 10px !important;
    align-items: center;
  }

  // div[ref='eDetailGrid'] div[aria-colindex='1'] {
  //   padding-left: 16px !important;
  // }

  .px-po-ag-grid-item-open .ag-row-group-indent-0 [ref='eValue'],
  .px-po-ag-grid-item-closed
    .ag-row-group-indent-0
    [ref='eValue']
    .px-po-ag-grid-item-Pending
    .ag-row-group-indent-0
    [ref='eValue'] {
    white-space: pre-wrap;
    line-height: 18px;
    padding-top: 1px;
  }

  .px-po-grid-fake-header {
    display: block;
    white-space: pre-wrap;
    opacity: 0.4;
    font-size: 12px;
    line-height: 14px;
  }

  .ag-theme-alpine .ag-floating-top,
  .ag-theme-alpine .ag-row,
  .ag-theme-alpine .ag-header-group-cell-with-group,
  .ag-theme-alpine .ag-header-row-column {
    border-bottom: 3px solid white !important;
  }

  div.ag-horizontal-left-spacer {
    overflow-x: clip;
  }

  .ag-body-viewport .ag-layout-auto-height .ag-row-no-animation {
    height: fit-content;
    width: 100%;
    overflow: hidden;
  }

  .ag-cell-wrapper.ag-row-group {
    padding-top: 19px;
  }

  .px-po-import-bar {
    .MuiButton-startIcon {
      margin-top: -3px;
    }

    svg {
      fill: rgba(var(--px_color_primary), 1) !important;
    }
  }

  .ag-theme-alpine {
    .ag-details-row {
      padding: 0px 0px 20px 10px;
    }
  }

  .px-po-grid-longtext .ag-cell-value {
    line-height: 20px !important;
    display: contents;
    vertical-align: middle;
    padding: 6px 0px;
    min-height: 52px;
    word-break: break-word;
  }

  .px-po-item-open-buyer {
    padding-right: 20px;
    word-break: break-word;
    line-height: 36px !important;

    svg {
      margin-top: 8px;
      display: none;
      float: right;
      cursor: pointer;
    }
  }

  .px-po-item-open-buyer:hover {
    padding-right: 20px;

    svg {
      display: block;
    }
  }

  .px-po-grid-header-align {
    padding-left: 45px !important;
    margin-left: -15px;
    text-overflow: clip !important;
  }

  .px-po-grid-second {
    padding-left: 27px !important;
  }

  .ag-theme-alpine .ag-cell[col-id='awardedPrice'] {
    .MuiOutlinedInput-adornedStart {
      padding-left: 5px;
    }
    .MuiInputAdornment-positionStart {
      margin-right: 5px;
    }
    .MuiOutlinedInput-adornedEnd {
      padding-right: 5px;
    }
  }

  .ag-theme-alpine .ag-cell[col-id='startingFund'],
  .ag-theme-alpine .ag-cell[col-id='endingFund'],
  .ag-theme-alpine .ag-cell[col-id='awardedPrice'],
  .ag-header-cell[col-id='startingFund'] .ag-header-cell-label,
  .ag-header-cell[col-id='endingFund'] .ag-header-cell-label,
  .right-align .ag-header-cell-label {
    justify-content: flex-end;
  }

  .ag-details-grid {
    .ag-header-cell[col-id='startingFund'],
    .ag-header-cell[col-id='endingFund'],
    .right-align {
      padding-right: 10px;
    }
  }
}

.px-fulfillment-table {
  height: 97vh;
}

.px-po-import-dialog {
  .MuiPaper-root {
    min-width: 480px;
    margin: 20px 160px;

    .MuiPaper-root {
      min-width: initial;
      margin: initial;
      background-color: RGB(var(--px_color_primary));
    }
  }

  .MuiDialogTitle-root {
    margin: 20px 0;
    text-align: left;
  }
}

.px-po {
  .px-po-header {
    button.px-big-icon {
      margin-left: 10px;
      vertical-align: top;
      margin-top: -6px;
    }

    .MuiFormControl-root {
      min-width: 300px;
    }
  }

  .ag-theme-alpine .ag-cell[col-id='category'],
  .ag-theme-alpine .ag-header-cell[col-id='category'] {
    left: 350px !important;
  }

  .ag-theme-alpine .ag-cell[col-id='category'],
  .ag-theme-alpine .ag-cell[col-id='grade'],
  .ag-theme-alpine .ag-cell[col-id='warehouse'],
  .ag-theme-alpine .ag-cell[col-id='totalQuantityOnHand'],
  .ag-theme-alpine .ag-cell[col-id='totalQuantityAllocated'],
  .ag-theme-alpine .ag-cell[col-id='totalQuantityAvailable'],
  .ag-theme-alpine .ag-cell[col-id='backordered'],
  .ag-theme-alpine .ag-cell[col-id='cost'] {
    padding-left: 8px;
  }

  // row group border:
  .ag-header-row .ag-header-cell[aria-colindex='7'][role='columnheader'],
  .ag-header-row .ag-header-group-cell[aria-colindex='7'][role='columnheader'],
  .ag-row .ag-cell[aria-colindex='7'][role='gridcell'] {
    border-left: 1px solid RGB(var(--px_color_border));
  }

  // .ag-row .ag-cell[aria-colindex='7'][role='gridcell'] {
  //   padding-left: 4px;
  // }

  .ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
    height: 100%;
  }

  .ag-cell[col-id='editField'] {
    padding: 0 !important;
  }

  .ag-cell-wrapper .MuiButton-root {
    background: #f0f0f0;
  }

  .px-po-grid-header {
    background-color: RGB(var(--color_badge_6));
  }
}

.px-po-review {
  .px-align-content-middle {
    display: flex;
    align-items: center;
  }

  .px-color-success {
    color: RGB(var(--px_color_success));
  }

  .px-color-success-variant {
    color: RGB(var(--px_color_success_text));
  }

  .px-color-error {
    color: RGB(var(--px_color_error));
  }

  .px-color-error-variant {
    color: RGB(var(--px_color_error_text));
  }

  .px-color-warning {
    color: RGB(var(--px_color_warning));
  }

  .px-color-warning-variant {
    color: RGB(var(--px_color_warning_text));
  }

  .px-color-change {
    color: RGB(var(--px_color_badge_3));
  }

  .px-color-change-variant {
    color: RGB(var(--px_color_badge_3b));
  }

  .px-error-tab .MuiBadge-badge {
    background-color: RGB(var(--px_color_error));
    color: #ffffff;
  }

  .px-warning-tab .MuiBadge-badge {
    background-color: RGB(var(--px_color_warning));
    color: #ffffff;
  }

  .px-change-tab .MuiBadge-badge {
    background-color: RGB(var(--px_color_badge_3));
    color: #ffffff;
  }

  .px-review-icon {
    font-size: 24px;
  }

  .px-po-meta-data-container {
    display: flex;
    flex-direction: row;

    .px-po-meta-data {
      display: flex;
      flex-direction: column;

      .px-bold-fonts {
        line-height: 24px;
      }

      .px-import-meta-data-vertical-margin {
        margin: 4px 0 4px 0;
      }
    }
  }

  .px-po-tabs button.MuiTab-root {
    min-height: 56px;
  }

  .px-po-review-header {
    background-color: RGB(var(--color_badge_6));
  }

  .px-po-highlight {
    background-color: bisque;
  }

  [col-id='description'] {
    padding: 4px;
    line-height: 18px !important;
  }

  .ag-cell-value {
    line-height: 18px;
    margin: 0;
  }

  .px-collapsed-icon {
    transform: rotate(-90deg);
    transition: 100ms all cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}

.px-message-info {
  color: #808080;
}

.px-message-warning {
  color: RGB(var(--px_color_warning));
}

.px-po-break-word {
  word-break: break-word !important;
}

.px-preorder .disable-checkbox input {
  pointer-events: none;
  cursor: default;
  border: 1px solid grey;
  background-color: lightgrey;
}

.px-import-review-header {
  padding-left: 0px !important;
}

.funding-notconfirmed-indicator {
  display: flex;
  background-color: #757575;
  color: white;
  padding: 5px;
  margin-bottom: 20px;
}

.po-snackBar-alert {
  .MuiAlert-filledInfo {
    background-color: RGB(var(--px_color_primary)) !important;
  }

  .MuiAlert-icon {
    padding-top: 14px;
  }

  .snackButton {
    margin-left: 5px;
    color: #ffffff !important;
    border: 1px solid #ffffff;
  }
}

.inventorySync {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.disablePointerFunding {
  color: RGB(var(--px_color_text_secondary)) !important;
}

.tooltip-style-error {
  background-color: transparent;
  color: red;
  height: 5px;
  padding-top: 0;
}

.tooltip-style-msg {
  background-color: transparent;
  color: black;
  height: 5px;
  padding-top: 0;
}

.itemBadgeIcon {
  height: 22px;
  width: 22px;
  background-color: RGB(var(--px_color_text_muted));
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 10px;
}

.itemBadgeIconValue {
  color: #ffffff;
  position: relative;
  top: 2px;
}

.ag-icon-tree-closed {
  font-size: 23px !important;
}

.ag-icon-tree-open {
  font-size: 23px !important;
}

.text-normal {
  color: gray;
}

.px-fulfillment-table-buyer {
  height: 95vh;
}

#calc-min-width {
  min-width: 400px;
}

.px-min-width-buyer {
  min-width: 2000px;
}

.po-grid-ordernumber {
  text-decoration: none;
  color: #0091ff !important;
  &:hover {
    color: rgba(var(--px_color_link), 1) !important;
  }
}
.eliminate-sort-order .ag-sort-order {
  // display: none;
  color: RGB(var(--px_color_text_secondary)) !important;
  font-size: 10px;
}
